<template>
  <q-table
    flat
    :class="{'x-responsive-table':true,'fixed-last-column':!!$slots.opes}"
    :rows="rows"
    :columns="allColumns"
    :row-key="rowKey"
    :pagination="pagination"
    :rows-per-page-options="rowsPerPageOptions"
    :grid="$q.screen.lt.sm"
    :bordered="$q.screen.lt.sm"
    @request="request"
    @update:pagination="updatePagination"
    @row-click="onRowClick"
  >
    <template v-if="$slots.top" v-slot:top>
      <slot name="top"/>
    </template>
    <template v-else-if="$slots.search" v-slot:top>
      <div v-show="autoHideSearch && $q.screen.lt.sm" class="row q-gutter-md">
        <q-btn
          label="筛选"
          icon="filter_alt"
          icon-right="keyboard_arrow_down"
          color="primary"
          outline
          @click="showSearchDialog=true"
        />
        <slot name="top-opes"/>
      </div>
      <q-form v-show="!autoHideSearch || !$q.screen.lt.sm" ref="searchForm" class="search-form row q-gutter-md" @submit="search()">
        <slot name="search"/>
        <q-btn label="搜索" icon="search" type="submit" color="primary" unelevated/>
        <slot name="top-opes"/>
      </q-form>
    </template>
    <template v-else-if="$slots['top-opes']" v-slot:top>
      <slot name="top-opes"/>
    </template>
    <template v-for="(slot,index) in columnSlots" :key="index" v-slot:[slot.bodyCellName]="props">
      <q-td>
        <slot :name="slot.name" v-bind="props"/>
      </q-td>
    </template>
    <template v-if="$slots.opes" v-slot:body-cell-_opes_="props">
      <q-td class="text-center">
        <slot name="opes" v-bind="props"/>
      </q-td>
    </template>
    <template v-slot:item="props">
      <div class="q-pa-sm col-12 grid-style-transition">
        <q-card>
          <q-card-section>
            <div class="row items-center no-wrap">
              <div class="col">
                <div class="text-h6" v-if="titleProp">{{props.row[titleProp]}}</div>
              </div>
              <div class="col-auto">
                <slot v-if="$slots.opes" name="opes" v-bind="props"/>
              </div>
            </div>
          </q-card-section>
          <q-separator/>
          <q-list dense>
            <q-item v-for="col in props.cols.filter(col => col.name !== '_opes_')" :key="col.name">
              <q-item-section class="card-label">
                <q-item-label caption>{{ col.label }}</q-item-label>
              </q-item-section>
              <q-item-section class="card-content" side>
                <template v-if="columnSlotsMap[col.name]">
                  <slot :name="columnSlotsMap[col.name].name" v-bind="props"/>
                </template>
                <q-item-label v-else class="text-black">{{ col.value }}</q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-card>
      </div>
    </template>
    <template v-slot:pagination="props">
      <span class="q-table__bottom-item" v-if="page">共 {{page.total}} 行</span>
      <q-pagination
        class="q-table__bottom-item"
        :model-value="props.pagination.page"
        :max="props.pagesNumber"
        @update:model-value="updateCurrentPage"
        input
      />
    </template>
  </q-table>
  <q-dialog v-if="autoHideSearch && $slots.search && $q.screen.lt.sm" v-model="showSearchDialog">
    <q-card style="width:400px;max-width:90vw;">
      <q-card-section class="row items-center">
        <div class="text-h6">筛选条件</div>
        <q-space/>
        <q-btn icon="close" flat round dense v-close-popup/>
      </q-card-section>
      <q-separator/>
      <q-card-section class="scroll" style="max-height:60vh">
        <q-form class="q-gutter-md" @submit="search()">
          <slot name="search"/>
        </q-form>
      </q-card-section>
      <q-separator/>
      <q-card-section class="row justify-end q-gutter-md">
        <q-btn
          label="清空"
          icon="filter_alt"
          color="negative"
          outline
          v-close-popup
          @click="clearSearch"
        />
        <q-btn
          label="搜索"
          icon="search"
          color="primary"
          unelevated
          v-close-popup
          @click="search()"
        />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
import { computed, getCurrentInstance, ref } from 'vue'

export default {
  name: 'XResponsiveTable',
  props: {
    url: { type: String, require: true },
    param: { type: Object, default: () => { return {} } },
    paramProps: { type: Object, default: () => { return {} } },
    columns: { type: Array, require: true },
    rowKey: { type: String, default: 'id' },
    titleProp: { type: String },
    rowsPerPageOptions: { type: Array, default: () => [5, 10, 20, 50, 100] },
    paramFilter: { type: Function, default: null },
    listResolver: { type: Function, default: null },
    autoHideSearch: { type: Boolean, default: true }
  },
  emits: [
    'update:param',
    'row-click'
  ],
  setup (props, { slots, emit }) {
    const { proxy } = getCurrentInstance()
    const defaultPageNum = 1
    const defaultPageSize = 10
    const page = ref({})
    const sortBy = ref(null)
    const descending = ref(null)
    const allColumns = computed(() => {
      const columns = props.columns
      return slots.opes
        ? columns.concat([{ label: '操作', name: '_opes_', align: 'center' }])
        : columns
    })
    const rows = computed(() => {
      return page.value.list || []
    })
    const pagination = computed(() => {
      const value = page.value
      return {
        page: value.pageNum || defaultPageNum,
        rowsPerPage: value.pageSize || defaultPageSize,
        rowsNumber: value.total || 0,
        sortBy: sortBy.value,
        descending: descending.value
      }
    })
    const sort = computed(() => {
      return sortBy.value ? [`${sortBy.value} ${descending.value ? 'DESC' : 'ASC'}`] : undefined
    })
    const innerParamProps = computed(() => {
      return Object.assign({
        pageNum: { type: Number, default: defaultPageNum },
        pageSize: { type: Number, default: defaultPageSize },
        include: { type: Array, internal: true },
        exclude: { type: Array, internal: true }
      }, props.paramProps)
    })
    const columnSlots = computed(() => {
      const result = []
      for (const name in slots) {
        if (name.startsWith('column-')) {
          const cellName = name.replace(/^column-/g, '')
          result.push({
            name: name,
            cellName,
            bodyCellName: `body-cell-${cellName}`
          })
        }
      }
      return result
    })
    const columnSlotsMap = computed(() => {
      const list = columnSlots.value
      const result = {}
      list.forEach((v) => { result[v.cellName] = v })
      return result
    })
    const showSearchDialog = ref(false)

    function getQueryParam () {
      const query = proxy.$route.query
      const result = Object.assign({}, props.param)
      for (const name in query) {
        const prop = innerParamProps.value[name] || {}
        const value = query[name]
        if (prop.internal) {
          continue
        }
        if (name === 'pageNum') {
          if (!isNaN(value)) {
            page.value.pageNum = parseInt(value)
          }
          continue
        }
        if (name === 'pageSize') {
          if (!isNaN(value)) {
            page.value.pageSize = parseInt(value)
          }
          continue
        }
        if (name === 'sort') {
          if (typeof value === 'string') {
            const array = value.split(' ')
            if (array.length) {
              sortBy.value = array[0]
            }
            if (array.length > 1) {
              descending.value = array[1].toUpperCase() === 'DESC'
            }
          }
          continue
        }
        if (prop.type === Number) {
          if (!isNaN(value)) {
            result[name] = Number(value)
          }
          continue
        }
        if (prop.type === Boolean) {
          result[name] = value === 'true'
          continue
        }
        if (prop.type === Array) {
          if (typeof value === 'string') {
            result[name] = [toItemType(value, prop.itemType)]
            continue
          }
          for (let i = 0; i < value.length; i++) {
            value[i] = toItemType(value[i], prop.itemType)
          }
        }
        result[name] = value
      }
      return result
    }

    function toItemType (value, itemType) {
      if (itemType === Number) {
        if (!isNaN(value)) {
          return Number(value)
        }
        return
      }
      if (itemType === Boolean) {
        return value === 'true'
      }
      return value
    }

    async function loadRows (param) {
      page.value = await proxy.$server.post(
        props.url,
        typeof props.paramFilter === 'function'
          ? props.paramFilter(Object.assign({}, param))
          : param)
      if (page.value.list && typeof props.listResolver === 'function') {
        page.value.list = page.value.list.map((item) => props.listResolver(item))
      }
      const query = toQuery(param)
      proxy.$router.replace({ query })
    }

    function toQuery (param) {
      if (param) {
        const query = {}
        for (const name in param) {
          const prop = innerParamProps.value[name] || {}
          const value = param[name]
          if (value !== null &&
            value !== undefined &&
            !prop.internal &&
            value !== prop.default) {
            query[name] = value
          }
        }
        return query
      }
    }

    async function request (req) {
      const pagination = req.pagination
      const newSortBy = pagination.sortBy
      const newDescending = pagination.descending
      await loadRows(Object.assign({}, props.param, {
        pageNum: pagination.page,
        pageSize: pagination.rowsPerPage,
        sort: newSortBy ? [`${newSortBy} ${newDescending ? 'DESC' : 'ASC'}`] : undefined
      }))
      sortBy.value = newSortBy
      descending.value = newDescending
    }

    async function search (param) {
      await loadRows(Object.assign({}, param || props.param, {
        pageNum: defaultPageNum,
        pageSize: page.value.pageSize || defaultPageSize,
        sort: sort.value
      }))
    }

    async function clearSearch () {
      const param = Object.assign({}, props.param)
      for (const name in param) {
        const prop = innerParamProps.value[name] || {}
        if (prop.internal) {
          continue
        }
        param[name] = null
      }
      emit('update:param', param)
      await search(param)
    }

    async function refresh (param) {
      const current = page.value
      await loadRows(Object.assign({}, param || props.param, {
        pageNum: current.pageNum || defaultPageNum,
        pageSize: current.pageSize || defaultPageSize,
        sort: sort.value
      }))
    }

    async function init () {
      const param = getQueryParam()
      emit('update:param', param)
      refresh(param)
    }

    function updatePagination () {
      // do nothing
    }

    function updateCurrentPage (page) {
      request({ pagination: Object.assign({}, pagination.value, { page }) })
    }

    function onRowClick (evt, row, index) {
      emit('row-click', evt, row, index)
    }

    init()

    return {
      page,
      allColumns,
      rows,
      pagination,
      columnSlots,
      columnSlotsMap,
      showSearchDialog,
      request,
      search,
      clearSearch,
      refresh,
      updatePagination,
      updateCurrentPage,
      loadRows,
      onRowClick
    }
  }
}
</script>
